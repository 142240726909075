import React from "react";
import {roundPrice} from "../../utils/roundPrice";
import RatingStar from "../rating/Star";
import {connect} from "react-redux";
import Image from "next/image";
import {stripHtml} from "string-strip-html";
import ProductVariantSelectors from "./variant/Selectors";
import ProductAddToCart from "./AddToCart";
import ProductWishlist from "./Wishlist";
import {setSelectedProduct} from "../../stores/product/productSelectorSlice";
import ProductBadge from "./Badge";
import imageService from "../../services/product/imageService";
import productLinkService from "../../services/product/productLinkService";
import {convertBxAttributes} from "../../utils/bxAttributes";
import ProductCompare from "./Compare";
import Link from "next/link";
import mainVariantService from "../../services/product/MainVariantService";
import MainVariantService from "../../services/product/MainVariantService";
import {imageSizes} from "../../utils/imageSizes";

export class ProductTile extends React.PureComponent<any, any> {
    product: any;
    heading: any;
    informationOnly: any;
    minimumStock: number;

    constructor(props: any, context: any) {
        super(props, context);
        this.product = props.product;
        this.informationOnly = props.informationOnly ?? false;
        this.heading = props.heading ?? 2;

        try {
            if (!this.product.cover.includes('cdn.perfecthair.ch')) {
                const cover = this.getCover(this.product, this.product);
                if (cover && cover.length) this.product.cover = cover;
            }
        } catch (e) {}

        this.minimumStock = MainVariantService.getMinimumStock(this.product.variants);

        this.state = {
            stateProduct: {...this.product}
        }

        if (this.product.variants) {
            let main = mainVariantService.getMain(this.product.variants);

            if (main) {
                const product: any = {...this.product};
                product.cover = this.getCover(product, main);
                product.giftBadge = main.giftBadge;
                product.reviews = main.reviews;
                product.reviewTotal = main.reviewTotal;
                product.releaseDate = main.releaseDate;
                product.topseller = main.topseller;
                product.price = main.price;
                product.pseudoPrice = main.pseudoPrice;
                product.link = main.link;
                product.colorCardId = main.colorCardId;
                product.colorCardArticles = main.colorCardArticles;
                product.lastStock = main.lastStock;
                product.stock = main.stock;
                product.orderNumber = main.orderNumber;
                product.isPharma = main.isPharma;
                product.pharmacode = main.pharmacode;
                product.isExpiring = Number(main.isExpiring);
                product.giftId = main.giftId;

                this.state = {
                    stateProduct: {...product}
                }
            }
        }
    }

    getCover(product: any, mainVariant: any) {
        if (product.cover && product.cover.includes('cdn.perfecthair.ch')) return product.cover;
        return mainVariant.imgDetailUrls ? imageService.getImage(mainVariant.imgDetailUrls, product.orderNumber) : imageService.getImage(mainVariant.imgMainUrls, product.orderNumber)
    }

    onVariantChange(id: any, $this: any) {
        const variant = $this.product.variants.find((v: any) => v.id === parseInt(id));
        const main = $this.product.variants.find((v: any) => $this.product.mainDetailId === v.id);

        const p = {...$this.product};
        p.id = variant.articleId;
        p.detailId = variant.id;
        p.orderNumber = variant.orderNumber;
        p.price = variant.price;
        p.pseudoPrice = variant.pseudoPrice;
        p.review = variant.review;
        p.reviewTotal = variant.reviewTotal;
        p.cover = variant.cover;
        p.lastStock = variant.lastStock;
        p.stock = variant.stock;
        p.imgUrls = variant.imgUrls;
        p.link = `${variant.link}?id=${variant.id}`;
        p.isExpiring = Number(variant.isExpiring);
        p.giftid = variant.giftId;
        p.isPharma = main.isPharma;
        p.pharmacode = main.pharmacode;
        p.cover = variant.imgDetailUrls ? imageService.getImage(variant.imgDetailUrls, variant.orderNumber) : imageService.getImage(variant.imgMainUrls, variant.orderNumber);
        $this.setState({stateProduct: {...p}});
        $this.props.setSelectedProduct(p);
    }

    addToCart() {
        if (this.state.stateProduct.isExpiring == 1 && this.state.stateProduct.stock <= 0) return <></>
        if (this.state.stateProduct.lastStock && this.state.stateProduct.stock <= 0) return <></>
        if (!this.state.stateProduct.lastStock && this.state.stateProduct.stock <= this.minimumStock) return <></>
        return <ProductAddToCart product={this.state.stateProduct} listing={this.props.listing}/>
    }

    render() {
        const {className = '', reviewTotal, style, showDescription, children} = this.props;
        if (children) return children;

        return (
            <div {...convertBxAttributes(this.product, `product-tile flex-fill position-relative dark-theme-bg-white ${className}`)} style={style}>
                <Link href={productLinkService.getLink(this.state.stateProduct)}
                      prefetch={false}
                      className="d-block dark">
                    <ProductBadge product={this.state.stateProduct}/>
                    <div className="image-container d-flex align-items-end justify-content-center my-3 mx-auto">
                        <Image src={this.state.stateProduct.imagePath ?? this.state.stateProduct.cover}
                               alt={this.state.stateProduct.articleName ?? this.state.stateProduct.name}
                               width={175}
                               height={175}
                               sizes={imageSizes({ xs: '125px', md: '175px' })}
                               className="mw-100 mh-100 w-auto h-auto"/>
                    </div>

                    <div className="product-tile-text product-tile-title mb-2">
                        <b>{this.state.stateProduct.supplierName ?? this.state.stateProduct.brand}</b></div>
                    {this.heading === 2 ? (
                        <h2 className="product-tile-text fs-8 fw-normal multi-line mt-0 mb-2">{this.state.stateProduct.articleName ?? this.state.stateProduct.name}</h2>
                    ) : (
                        <h3 className="product-tile-text fs-8 fw-normal multi-line mt-0 mb-2">{this.state.stateProduct.articleName ?? this.state.stateProduct.name}</h3>
                    )}

                    {this.state.stateProduct.price && !this.informationOnly ? (
                        <div className="flex flex-row">
                            <span className="fw-bold">{roundPrice(this.state.stateProduct.price)}</span>
                            <span className="text-decoration-line-through ms-2 pseudo-price">
                              {(roundPrice(this.state.stateProduct.price) !== roundPrice(this.state.stateProduct.pseudoPrice)) && (
                                  <span>{roundPrice(this.state.stateProduct.pseudoPrice)}</span>
                              )}
                            </span>
                        </div>
                    ) : <></>}

                    {!this.informationOnly ? (
                        <RatingStar rating={this.state.stateProduct.reviews}
                                    total={this.state.stateProduct.reviewTotal}
                                    className={''}
                                    showTotal={reviewTotal}
                                    showRating={false}/>
                    ) : <></>}
                </Link>

                {!this.informationOnly ? (
                    <div className={"variant-container mt-3"}>
                        <ProductVariantSelectors variants={this.state.stateProduct.variants}
                                                 product={this.state.stateProduct}
                                                 $this={this}
                                                 onVariantChange={this.onVariantChange}/>
                    </div>
                ) : <></>}

                {this.product.orderNumber && !this.informationOnly ? (
                    <div className={"actions p-0"}>
                        <div className={"row g-1 w-100"}>
                            <div className={"col-3"}>
                                <ProductCompare product={this.state.stateProduct}/>
                            </div>
                            <div className={"col-3"}>
                                <ProductWishlist product={this.state.stateProduct}/>
                            </div>
                            <div className={"col-6"}>
                                {this.addToCart()}
                            </div>
                        </div>
                    </div>
                ) : <></>}

                <Link
                    href={productLinkService.getLink(this.state.stateProduct)}
                    passHref prefetch={false}
                    className="d-block dark">
                    {showDescription && (
                        <p>{stripHtml(this.state.stateProduct.descriptionLong).result.substring(0, 500)}...</p>
                    )}
                </Link>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedProduct: (value: any) => dispatch(setSelectedProduct(value))
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTile)
